const products = {
    highSpeeds: [
        {
            id: "9810029",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FBIEN%20AIR%20PRESTIGE%20(SWISS%20MADE).jpg?alt=media&token=0cb75377-242e-4472-a04c-3c7814cd1347",
            name: "BIEN AIR PRESTIGE (SWISS MADE)",
            org_price: "1498",
            sale_price: "699",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "99JO23O",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FBIEN%20AIR%20PRESTIGE%20(SWISS%20MADE)(2).jpg?alt=media&token=afa1bca0-1b44-42c1-a59f-66447c00a0ec",
            name: "BIEN AIR PRESTIGE (SWISS MADE)",
            org_price: "1177",
            sale_price: "570",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "021",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FBIEN%20AIR%20HANDPIECE%20COUPLER.jpg?alt=media&token=1ec422b6-0891-426b-a7db-9f5b9249f467",
            name: "BIEN AIR HANDPIECE COUPLER",
            org_price: "245",
            sale_price: "122",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "1609260017",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2F45%C2%BA%20DENTAL%20SURGERY.jpg?alt=media&token=74032c24-8939-42a8-8e39-e3a027ee248a",
            name: "45º DENTAL SURGERY",
            org_price: "1498",
            sale_price: "125",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "28870",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FHEALTHCO%20MINI%20(PEDODONTICO).jpg?alt=media&token=f9ceab4f-5bc0-4711-9394-33770705fc81",
            name: "HEALTHCO MINI (PEDODONTICS)",
            org_price: "374",
            sale_price: "170",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "28838",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FHEALTHCO%20MINI%20(PEDODONTICO)(2).jpg?alt=media&token=858fabf9-32a2-4be3-bd04-468c8fcbe587",
            name: "HEALTHCO MINI (PEDODONTICS)",
            org_price: "374",
            sale_price: "224",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "28869",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FHEALTHCO%20MINI%20(PEDODONTICO)(3).jpg?alt=media&token=dd7251a0-c440-4ac8-981b-4ac4f4b9d042",
            name: "HEALTHCO MINI (PEDODONTICS)",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "29036",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FHEALTHCO%20MINI%20(PEDODONTICO)(4).jpg?alt=media&token=29b07d5c-4af4-4be1-852d-51998aa4f97e",
            name: "HEALTHCO MINI (PEDODONTICS)",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "01700508200",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FHIGH%20SPEED%20DRILL.jpg?alt=media&token=1adf7672-505a-47f1-b7fc-5eb656ab5f4e",
            name: "HIGH SPEED DRILL",
            org_price: "none",
            sale_price: "70",
            on_sale: false,
            sold: false,
            high_speed: true
        },
        {
            id: "0190125819",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FHIGH%20SPEED%20DRILL(2).jpg?alt=media&token=69d2e40f-7a55-4e3e-8e74-e5232bcf7c92",
            name: "HIGH SPEED DRILL",
            org_price: "none",
            sale_price: "70",
            on_sale: false,
            sold: false,
            high_speed: true
        },
        {
            id: "P54032",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FIMPACT%20AIR%2045.jpg?alt=media&token=07b36645-7b99-4774-9fdd-8752143df590",
            name: "IMPACT AIR 45",
            org_price: "909",
            sale_price: "327",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "P54033",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FIMPACT%20AIR%2045(2).jpg?alt=media&token=7790dce6-206d-4b66-bd05-7a95f7b74b2a",
            name: "IMPACT AIR 45",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "P36154",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FIMPACT%20AIR%2045(3).jpg?alt=media&token=bda637b5-48bd-4cac-8888-20d0cf9763d1",
            name: "IMPACT AIR 45",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "P3C766",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FIMPACT%20AIR%2045(4).jpg?alt=media&token=3639594d-67e8-496c-b2ed-83556d7bb671",
            name: "IMPACT AIR 45",
            org_price: "914",
            sale_price: "450",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "28426",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FKAVO%20CONTACT%20AIR%20632%20D.jpg?alt=media&token=4f65f9a1-5c0a-412c-81e5-4430e48c6368",
            name: "KAVO CONTACT AIR 632 D",
            org_price: "781",
            sale_price: "340",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "B180497",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FKAVO%20SUPER%20TORQUE.jpg?alt=media&token=e8a5e031-887a-4c4d-ada4-99a33ec3951e",
            name: "KAVO SUPER TORQUE",
            org_price: "1224",
            sale_price: "520",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "3942",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FKAVO%20SUPER%20TORQUE%20625C.jpg?alt=media&token=cb411edb-3f23-4e63-b007-a4d5f0595d80",
            name: "KAVO SUPER TORQUE 625C",
            org_price: "599",
            sale_price: "299",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "N295489",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FKAVO%20(MULTIFLEX%20LUX%20COUPLER).jpg?alt=media&token=534b86a4-b6b0-4b8d-bdee-4660557bd537",
            name: "KAVO (MULTIFLEX LUX COUPLER)",
            org_price: "412",
            sale_price: "199",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "842229",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FLARES%20SET%20OF%203%20HSHP.jpg?alt=media&token=e0d21346-afc7-431f-a697-b664d7d5ddbf",
            name: "LARES SET OF 3 HSHP",
            org_price: "none",
            sale_price: "997",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "846566",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FLARES%20USA.jpg?alt=media&token=7d03df93-cda1-4d18-9cba-c112266121bb",
            name: "LARES USA",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "117361",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FLARES%20USA%20ENGINEERED.jpg?alt=media&token=10a02c2d-0f15-4545-9db2-91282e9a007a",
            name: "LARES USA ENGINEERED",
            org_price: "434",
            sale_price: "217",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "117412",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FLARES%20USA%20ENGINEERED(2).jpg?alt=media&token=17b4e983-d89d-4e89-8c20-1e693c81929e",
            name: "LARES USA ENGINEERED",
            org_price: "434",
            sale_price: "217",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "117414",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FLARES%20USA%20ENGINEERED(3).jpg?alt=media&token=d5950d1e-54b4-4f63-8bcc-a93d0c96aa45",
            name: "LARES USA ENGINEERED",
            org_price: "434",
            sale_price: "217",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "193109",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FMIDWEST%20QUIET%20AIR.jpg?alt=media&token=cd4be105-5aa5-4e43-8275-b26cbdc23123",
            name: "MIDWEST QUIET AIR",
            org_price: "916",
            sale_price: "416",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "281541A",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FMIDWEST%20QUIET%20AIR(2).jpg?alt=media&token=0d27020f-921e-4d51-9367-d23bd93f6fcb",
            name: "MIDWEST QUIET AIR",
            org_price: "916",
            sale_price: "416",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "3001836",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FMIDWEST%20STYLUS%20MADE%20IN%20USA.jpg?alt=media&token=f5b9b650-b06c-4c34-9c6a-734c2176a16a",
            name: "MIDWEST STYLUS MADE IN USA",
            org_price: "1629",
            sale_price: "770",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "24062703",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FMIDWEST%20TRADITION%20(AC%20FIBER%20OPTIC).jpg?alt=media&token=da2f0d67-beec-446c-af23-534bb2506c78",
            name: "MIDWEST TRADITION (AC FIBER OPTIC)",
            org_price: "1160",
            sale_price: "570",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "24035738",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FMIDWEST%20TRADITION%20(AC%20FIBER%20OPTIC)(2).jpg?alt=media&token=f119b2cb-feb2-48a7-8bfa-55511011164e",
            name: "MIDWEST TRADITION (AC FIBER OPTIC)",
            org_price: "1160",
            sale_price: "565",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "52157137",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FMIDWEST%20TRADITION%20L%20(AC%20FIBER%20OPTICS)(2).jpg?alt=media&token=2720911c-ba88-493f-bbf9-4df0c2856795",
            name: "MIDWEST TRADITION L (AC FIBER OPTIC)",
            org_price: "1160",
            sale_price: "565",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "52157233",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FMIDWEST%20TRADITION%20L%20(AC%20FIBER%20OPTICS)(3).jpg?alt=media&token=aa9d7261-8090-417c-a2df-66d247de80af",
            name: "MIDWEST TRADITION L (AC FIBER OPTIC)",
            org_price: "1160",
            sale_price: "565",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "52021566",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FMIDWEST%20TRADITION%20L%20(AC%20FIBER%20OPTICS)(4).jpg?alt=media&token=c0315745-7b2a-4943-ab9c-7399fc6397a4",
            name: "MIDWEST TRADITION L (AC FIBER OPTIC)",
            org_price: "1160",
            sale_price: "565",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "52255664",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FMIDWEST%20TRADITION%20L%20(AC%20FIBER%20OPTICS)(5).jpg?alt=media&token=5049df53-7e1e-4c77-9b1e-b297d99d1af2",
            name: "MIDWEST TRADITION L (AC FIBER OPTIC)",
            org_price: "1160",
            sale_price: "565",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "52093009",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FMIDWEST%20TRADITION%20LEVER.jpg?alt=media&token=2d03cc6b-0360-4069-8534-e27a90c3c630",
            name: "MIDWEST TRADITION LEVER",
            org_price: "871",
            sale_price: "425",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "B4813185",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FNSK%20COUPLING(B4813185).jpg?alt=media&token=2ce347bb-c123-485e-a74f-109eda201d1d",
            name: "NSK COUPLING",
            org_price: "226",
            sale_price: "107",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "CGY16706",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FNSK%20COUPLING(2).jpg?alt=media&token=f643ac10-e3a6-4b00-8576-9fe6dd8d2e4c",
            name: "NSK COUPLING",
            org_price: "226",
            sale_price: "107",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "B4812880",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FNSK%20COUPLING.jpg?alt=media&token=6c7e404d-936d-450f-ae7f-6b46794769e5",
            name: "NSK COUPLING",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "B4701407",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FNSK%20N-45%20S%20(ORAL%20SURGERY)(B4701407).jpg?alt=media&token=31b2d4e0-92ba-4633-993b-25359d83b51f",
            name: "NSK N45 S (ORAL SURGERY)",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "B4801438",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FNSK%20N-45%20S%20(ORAL%20SURGERY).jpg?alt=media&token=3974bd65-7bce-4c21-a8d7-4a223b91a21c",
            name: "NSK N45 S (ORAL SURGERY)",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "B4804714",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FNSK%20TI-MAX%2095L.jpg?alt=media&token=86432b15-bf04-4021-9746-dbf85d71ac9b",
            name: "NSK TI-MAX 95L",
            org_price: "1475",
            sale_price: "899",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "G02083",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FSTAR%20430K%20VISTA%20AC(G02083).jpg?alt=media&token=b3cce470-730d-4ff9-aa17-9e5aa385cfab",
            name: "STAR 430K AC",
            org_price: "850",
            sale_price: "420",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "601814",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FSTAR%20430K%20VISTA%20AC.jpg?alt=media&token=18a0e135-a3ab-4ee2-86a3-c63078391bc9",
            name: "STAR 430K VISTA AC",
            org_price: "850",
            sale_price: "399",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "A10169",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FSTAR%20(HIGH%20SPEED%20FIBER%20OPTIC).jpg?alt=media&token=2c34fcef-61a3-4fc3-9bed-dff4fddc8d1e",
            name: "STAR (HIGH SPEED FIBER OPTIC)",
            org_price: "333",
            sale_price: "159",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "C11436",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FSTAR%20(HIGH%20SPEED%20FIBER%20OPTIC)(2).jpg?alt=media&token=1f5bd654-8052-43b8-ab2b-b3d148c3ebdf",
            name: "STAR (HIGH SPEED FIBER OPTIC)",
            org_price: "333",
            sale_price: "159",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "P01469C4",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FSTAR%20(HIGH%20SPEED%20FIBER%20OPTIC)(3).jpg?alt=media&token=a1159ef0-8a5f-4c0c-8791-f7a80a4c50d9",
            name: "STAR (HIGH SPEED FIBER OPTIC)",
            org_price: "333",
            sale_price: "159",
            on_sale: true,
            sold: false,
            high_speed: true
        },
        {
            id: "C08773",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FSTAR%20SWIVEL%20AUTO%20CHUCK.jpg?alt=media&token=8496e77b-f22f-4a7e-a466-d306f983fa12",
            name: "STAR SWIVEL AUTO CHUCK",
            org_price: "1342",
            sale_price: "630",
            //530 without fiber optic.
            on_sale: true,
            sold: false,
            high_speed: true
        },{
            id: "N02712B3",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FSTAR%20SWIVEL%20FIBER%20OPTIC.jpg?alt=media&token=c7aa34f5-8d25-4b30-a363-9a0287b2cd04",
            name: "STAR SWIVEL FIBER OPTIC",
            org_price: "630",
            sale_price: "1342",
            on_sale: true,
            sold: false,
            high_speed: true
        },{
            id: "Fidelity 9.14.15",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FSTAR%20SWIVEL%20FIBER%20OPTIC(2).jpg?alt=media&token=6839acc3-4805-4d88-ab0f-ba5d7a738563",
            name: "STAR SWIVEL FIBER OPTIC",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "503417",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FSTAR%20SWIVEL%20FIBER%20OPTIC(3).jpg?alt=media&token=62c89e4d-7828-4861-b91e-bc129e167610",
            name: "STAR SWIVEL FIBER OPTIC",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "N00289B3",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FSTAR%20SWIVEL%20FIBER%20OPTIC(4).jpg?alt=media&token=a24e4b7b-126b-45d5-adb0-59a0643b29b0",
            name: "STAR SWIVEL FIBER OPTIC",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
        {
            id: "H15958",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/High%20Speeds%2FSTAR%20SWIVEL%20FIBER%20OPTIC(5).jpg?alt=media&token=22e08edb-f69f-4f10-a7d0-9cec49a5825f",
            name: "STAR SWIVEL FIBER OPTIC",
            org_price: "SOLD",
            sale_price: "SOLD",
            on_sale: false,
            sold: true,
            high_speed: true
        },
    ],
    lowSpeeds: [
        {
            id: "186209",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FENDODONTICS_22-1.jpg?alt=media&token=3e6b7421-61cb-4241-a7fd-5bf18b3e7e7a",
            name: "ENDODONTIC 22:1",
            org_price: "802",
            sale_price: "360",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "08-1005352",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FKAVO%20SUPER%20TORQUE%20LUX%20659B.jpg?alt=media&token=d1ab70b8-7644-4915-b6f6-5cb278a1c3f6",
            name: "KAVO SUPER TORQUE LUX 659B",
            org_price: "1630",
            sale_price: "699",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "F1D123",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FMIDWEST%20LATCH%20ATTACHMENT.jpg?alt=media&token=9f191c98-baab-47b2-8a8b-e812c50cf38b",
            name: "MIDWEST LATCH ATTACHMENT",
            org_price: "680",
            sale_price: "320",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "0021127",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FMIDWEST%20LATCH%20HEAD%20%E2%80%93%20NEW.jpg?alt=media&token=b240e34a-ab18-494a-a3d8-6739a4c316e2",
            name: "MIDWEST LATCH HEAD – NEW",
            org_price: "798",
            sale_price: "498",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "15010379",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FMIDWEST%20NOSE%20CONE.jpg?alt=media&token=c0ce0f22-e8bf-4124-afbd-8912059ee1b4",
            name: "MIDWEST NOSE CONE",
            org_price: "503",
            sale_price: "240",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "327288",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FMIDWEST%20SHORTY%202%20SPEED.jpg?alt=media&token=a640fcf3-8405-4870-92b8-0204f9fc9002",
            name: "MIDWEST SHORTY 2 SPEED",
            org_price: "1247",
            sale_price: "550",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "F1D2",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FNSK%20SAFCO.jpg?alt=media&token=f492d5e7-0807-4771-b33e-81dcc0771cb6",
            name: "NSK SAFCO",
            org_price: "1630",
            sale_price: "597",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "FIDI",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FNSK%20TECHNI%20TOOL.jpg?alt=media&token=b88b1b90-f09a-4079-806c-2b238b3b5d6d",
            name: "NSK TECHNI TOOL",
            org_price: "299",
            sale_price: "100",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "58449",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FSTAR%20AIR%20MOTOR%205000.jpg?alt=media&token=40076c69-e27d-4ca2-9d18-3b801818c0b7",
            name: "STAR AIR MOTOR 5000",
            org_price: "995",
            sale_price: "440",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "215321",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FSTAR%20DENTAL.jpg?alt=media&token=1bc10124-4b51-4607-a6b4-f4ab98246fff",
            name: "STAR DENTAL",
            org_price: "232",
            sale_price: "115",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "101312",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FSTAR%20DENTALEZ(CHECK%20NAME).jpg?alt=media&token=84dd111b-7868-4485-84ea-443127d6b6c2",
            name: "STAR DENTALEZ",
            org_price: "1462",
            sale_price: "599",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "75550",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FSTAR%20DENTALEZ(2).jpg?alt=media&token=3b7d7ca9-c070-496d-aa3b-a876943d1cc5",
            name: "STAR DENTALEZ",
            org_price: "1462",
            sale_price: "599",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "85706",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FSTAR%20DENTALEZ(3).jpg?alt=media&token=90651717-203b-4def-a0d3-41827bcb826b",
            name: "STAR DENTALEZ",
            org_price: "1462",
            sale_price: "599",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "34854",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FSTAR%20DENTALEZ(4).jpg?alt=media&token=fcaf5c6f-248b-472a-9243-363d27b9e302",
            name: "STAR DENTALEZ",
            org_price: "1462",
            sale_price: "599",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "92588",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FSTAR%20LATCH%20HEAD%20%E2%80%93%20NEW.jpg?alt=media&token=e462a3b3-1ca2-487b-b992-096debacf965",
            name: "STAR LATCH HEAD – NEW",
            org_price: "533",
            sale_price: "399",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "J33165",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FSTAR%20STRAIGHT%20NOSE%20CONE.jpg?alt=media&token=88e87b06-1ae1-42fb-bd5b-2b1164fcd680",
            name: "STAR STRAIGHT NOSE CONE",
            org_price: "503",
            sale_price: "240",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "042597",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FSTAR%20TITAN%20SWIVEL%2020K%20AIR%20MOTORS%20%26%20STAR%20DENTAL%20QUICK%20DISCONNECT.jpg?alt=media&token=0b7e2c7f-560d-4375-9820-fd22301d4bc9",
            name: "STAR TITAN SWIVEL 20K AIR MOTORS & STAR DENTAL QUICK DISCONNECT",
            org_price: "2222",
            sale_price: "1065",
            on_sale: true,
            sold: false,
            high_speed: false,
        },
        {
            id: "13F0018",
            tn_img: "https://firebasestorage.googleapis.com/v0/b/fidelity-dental-handpiec-8a3e7.appspot.com/o/Low%20Speeds%2FSTAR%20TWIST%20NOSECONE.jpg?alt=media&token=8e8cb2da-3c30-4adc-b0fd-ccdd85bff4ef",
            name: "STAR TWIST NOSECONE",
            org_price: "615",
            sale_price: "370",
            on_sale: true,
            sold: false,
            high_speed: false,
        },

    ],
};

export default products;