import classes from './HighSpeeds.module.css';
import StoreItem from './StoreItem';

const HighSpeeds = (props) => {
    if (!props.product_data) {
        console.log('No product data available');
        return null;  // Or return some loading/error state
    }
    
    const data_list = Object.keys(props.product_data).map(el => props.product_data[el]);
    console.log(data_list);

    return (
        <>
            <h3 className={classes.hs_title}>Pneumatic High Speeds</h3>
            <div className={classes.high_speeds}>
                {props.hsProductData.map((item) => (
                    <StoreItem key={item.id} item={item} viewProduct={props.onAppear} />
                ))}
            </div>
        </>
    )
};

export default HighSpeeds;
