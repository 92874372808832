import React from 'react';
import classes from './RepairPrices.module.css';
import { CiLocationOn } from "react-icons/ci";
import { IoSpeedometerOutline } from "react-icons/io5";
import { IoMdSpeedometer } from "react-icons/io";
import { AiTwotoneApi } from "react-icons/ai";
import { MdOutlineScale } from "react-icons/md";


const Services = () => {
    return (
        <div className={classes.service_page}>
            <div className={classes.section_container}>
                <h1 className={classes.title}>FIDELITY DENTAL HANDPIECE</h1>
                <h4 className={classes.greeting}>Grateful to serve you!</h4>
                <p className={classes.explanation}>
                    We service all foreign and domestic high and low speed pneumatic dental handpieces 
                    and ultrasonic scalers. (We are also trained on all electric high speeds and ask 
                    that you call for pricing on the Electrics.) Fidelity pays for domestic delivery 
                    confirmation shipping and handling both ways. Please use our USPS/XPS Postage Paid 
                    Permit Label. International tariffs and shipping to Fidelity should be paid for by 
                    the handpiece owner. Fidelity pays return costs. The prices below are your total 
                    amount, including all taxes. Our practice is to service the instrument within 24 
                    hours upon receipt. If the handpiece is found to require only analysis, simple 
                    adjustment, cleaning, lubrication and testing: Low Speed Motor $90. All other 
                    pieces $65. New Stainless Steel threads: $97. (We replace Fiber Optics only upon 
                    request – please call for a price quote.)
                </p>
            </div>

            <div className={classes.section_container}>
                <div className={classes.high_speeds}>
                    <h3 className={classes.title}>
                        {/* <FaTools size={24} />*/}<IoSpeedometerOutline /> HIGH SPEED DENTAL HANDPIECE 
                    </h3>
                    <h3 className={classes.hs_price}>
                        STANDARD REPAIR: $95
                    </h3>
                </div>
                <p>
                    This includes: Failure analysis, disassembly, technical cleaning, replacement of 
                    all components necessary to achieve optimum performance, replace front and rear 
                    bearing sets and O rings, balance, service chucking mechanism as needed, lubrication 
                    and reassemble. Fidelity then runs load tests at 35 psi to assure that speed, cutting 
                    torque (on steel), concentricity and noise/vibration levels comply with factory 
                    specifications. Complimentary fiber optic and head polishing ($47 value). See Warranty.
                </p>
                <div className={classes.other}>
                    <div className={classes.other_header}>
                        OTHER SERVICES (Only if necessary, and in addition to Standard Repair)
                    </div>
                    <div className={classes.other_container}>
                        <div className={classes.other_section}>
                            <div className={classes.o__s}>
                                <span>Autochuck replacement</span><span>$76</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Impeller replacement</span><span>$22</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Reset dislodged drive/water lines (epoxy)</span><span>$134</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Push button end cap</span><span>$76</span>
                            </div>
                            <div className={classes.o__s}>
                                {/* Ask Uncle Rod if this \/ is intentional */}
                                <span>
                                    AC bearing retention serv., Manual spindle, New set screw drill - tap - seat
                                </span>
                                <span>$35</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Ceramic lube free bearings</span><span>$44</span>
                            </div>
                        </div>
                        <div className={classes.other_section}>
                            <div className={classes.o__s}>
                                <span>
                                    Slinger, Pressure plate, Teflon buffer, Service impeller, Turbine spring
                                </span>
                                <span>$12</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>
                                    Ream head, Remove water blockage, Extensive
                                    internal rust removal, Heated realignment H2O 
                                    port & Varied heat treatment processes, Manual chuck
                                </span>
                                <span>$25</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.section_container}>
                <div className={classes.high_speeds}>
                <h3 className={classes.title}><IoMdSpeedometer />LOW SPEED DENTAL HANDPIECE</h3>
                    <h3 className={classes.hs_price}>STANDARD REPAIR: $159</h3>
                </div>
                <p>
                    This includes: Failure analysis, disassembly, technical cleaning, replacement of all components
                    necessary to achieve optimum performance, lubrication, balance and reassembly. Fidelity then
                    runs load tests at 40 psi to assure that: speed, cutting torque (on steel),
                    concentricity and noise/vibration levels comply with factory specifications. See Warranty.
                </p>
                <div className={classes.other}>
                    <div className={classes.other_header}>
                        OTHER SERVICES (Only if necessary, and in addition to Standard Repair)
                    </div>
                    <div className={classes.other_container}>
                        <div className={classes.other_section}>
                            <div className={classes.o__s}>
                                <span>Kavo, W&H or Euro. standard repair</span><span>$275</span>
                            </div>
                            <div className={classes.o__s}>
                                {/*Ask uncle Rod about if this means minimum -------------------\/   */}
                                <span>Chuck sleeve, collet, impeller or spindle</span><span>$53 min</span>
                            </div>
                        </div>
                        <div className={classes.other_section}>
                            <div className={classes.o__s}>
                                <span>Body or sheath replacement</span>
                                {/* And this \/ */}
                                <span>(varies min.) 87</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Ream chuck sleeve</span>
                                <span>$25</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.section_container}>
                <div className={classes.high_speeds}>
                    <h3 className={classes.title}>
                        <AiTwotoneApi />
                        LOW SPEED HANDPIECE ATTACHMENTS
                    </h3>
                    <h3 className={classes.hs_price}>STANDARD REPAIR: $95</h3>
                </div>
                <p>
                    This includes: Failure analysis, disassembly, technical cleaning, replacement of all components 
                    necessary to achieve optimum performance, overhaul front and rear bearing sets as needed, 
                    lubrication, chucking mechanism serviced as needed, balance, lubrication and reassembly. 
                    Fidelity then runs load tests at 40 psi to assure that speed, cutting torque (on steel), 
                    concentricity and noise / vibration levels comply with factory specifications. See Warranty.
                </p>
                <div className={classes.other}>
                    <div className={classes.other_header}>
                        OTHER SERVICES (Only if necessary, and in addition to Standard Repair)
                    </div>
                    <div className={classes.other_container}>
                        <div className={classes.other_section}>
                            <div className={classes.o__s}>
                                <span>Kavo, W&H, Endo., Implant or European Standard Repair</span><span>$157</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Drive dog, Geared drive shaft</span><span>$31</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Non standard gear replacement</span><span>$25</span>
                            </div>
                        </div>
                        <div className={classes.other_section}>
                            <div className={classes.o__s}>
                                <span>Body or sheath replacement</span><span>(varies min.) $37</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Spindle main or collet</span><span>(varies min.) $52</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Ream head or sheath. Retaining pin</span><span>$25</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Ceramic Lube Free Bearings</span><span>$44</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.section_container}>
                <div className={classes.high_speeds}>
                    <h3 className={`${classes.title} ${classes.scaler}`}>
                    <MdOutlineScale />
                        PNEUMATIC ULTRASONIC SCALING INSTRUMENT
                    </h3>
                    <h3 className={classes.hs_price}>STANDARD REPAIR: $105</h3>
                </div>
                <p>
                    This includes: Failure analysis, disassembly, technical cleaning, replacement of all components 
                    necessary to achieve optimum performance, balance and reassembly. Fidelity then load tests 
                    instruments at 45 psi to assure that scaling action, water flow and serviceability comply with 
                    factory specifications. See Warranty.
                </p>
                <div className={classes.other}>
                    <div className={classes.other_header}>
                        OTHER SERVICES (Only if necessary, and in addition to Standard Repair)
                    </div>
                    <div className={classes.other_container}>
                        <div className={classes.other_section}>
                            <div className={classes.o__s}>
                                <span>Kavo, European standard repair</span><span>$197</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Water line replacement</span><span>$44</span>
                            </div>
                        </div>
                        <div className={classes.other_section}>
                            <div className={classes.o__s}>
                                <span>Silver solder S.S. tube line</span><span>$70</span>
                            </div>
                            <div className={classes.o__s}>
                                <span>Heat treated realignment of file guide/water spout</span><span>$26</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.footer}>
                <h4 className={classes.footer_content}>
                    FIDELITY DENTAL HANDPIECE SERVICE AND SALES
                </h4>
                <h4 className={classes.footer_content}>
                    <CiLocationOn />
                    4330 Black Oak Drive Indianapolis, Indiana 46228 USA Ph. 1. 317. 254. 0277
                </h4>
            </div>
        </div>
    )
};

export default Services;
