import { useRef } from 'react';
import emailjs from '@emailjs/browser';

import classes from './Footer.module.css';

const Footer = () => {
    const form = useRef(null);

    const emailHandler = (event) => {
        event.preventDefault();
        console.log(form.current);
        const service_id = process.env.REACT_APP_SERVICE_ID;
        emailjs.sendForm('service_sze4myn', 'template_ruwd5hi', form.current, {
            publicKey: 'GlErRo0PfTnqR9nXt',
        }).then(
            () => { console.log('SUCCESS!') },
            (error) => {console.log('FAILED...', error.text)}
        );

        event.target.reset()
    };


    return (
        <div className={classes.contact}>
            <section className={classes.contact_info}>
                <h1 className={classes.title}>Contact Us</h1>
                <p className={classes.spiel}>
                    Feel free to reach out to us here with any questions, purchase intrests, or 
                    to just introduce yourself. We're ready to serve you!
                </p>
            </section>
            <section className={classes.form_section}>
                <form action='submit' className={classes.form} onSubmit={emailHandler} ref={form}>
                    <input type='text' name='name' placeholder='Full Name' required />
                    <input type='email' name='email' placeholder='Email' required />
                    <textarea name='message' className={classes.user_message} placeholder='Message' required />
                    <button type='submit'>Send</button>
                </form>
            </section>
        </div>
    )
};

export default Footer;