import { useState, useRef } from 'react';
import classes from './OrderForm.module.css';
import auxCls from './ProductDetails.module.css';
import useValidation from '../../custom hooks/useValidation';
import emailjs from '@emailjs/browser';

const emptyCheck = (value) => { return value.trim() !== '' };

const OrderForm = (props) => {
    const [countryCode, setCountryCode] = useState('+1');
    const [selectedCountry, setSelectedCountry] = useState('United States');
    const orderFormRef = useRef(null);

    const {
        value: firstName,
        inputIsValid: firstNameValid,
        inputHasError: firstNameError,
        inputValueHandler: firstNameHandler,
        inputBlurHandler: firstNameBlur,
        inputReset: firstNameReset,
    } = useValidation(emptyCheck);
    
    const {
        value: lastName,
        inputIsValid: lastNameValid,
        inputHasError: lastNameError,
        inputValueHandler: lastNameHandler,
        inputBlurHandler: lastNameBlur,
        inputReset: lastNameReset,
    } = useValidation(emptyCheck);
    
    const {
        value: phoneNumber,
        inputIsValid: phoneNumberValid,
        inputHasError: phoneNumberError,
        inputValueHandler: phoneNumberHandler,
        inputBlurHandler: phoneNumberBlur,
        inputReset: phoneNumberReset,
    } = useValidation((phoneNumber) => {
        const numIsValid = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/, '');
        return numIsValid.test(phoneNumber.trim());
    });

    const {
        value: location,
        inputIsValid: locationValid,
        inputHasError: locationError,
        inputValueHandler: locationHandler,
        inputBlurHandler: locationBlur,
        inputReset: locationReset,
    } = useValidation(emptyCheck);

    const {
        value: email,
        inputIsValid: emailValid,
        inputHasError: emailError,
        inputValueHandler: emailHandler,
        inputBlurHandler: emailBlur,
        inputReset: emailReset,
    } = useValidation((email) => {
        const emailIsValid = 
            new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, '');
        return emailIsValid.test(email.trim());
    });

    const {
        value: message,
        inputIsValid: messageValid,
        inputHasError: messageError,
        inputValueHandler: messageHandler,
        inputBlurHandler: messageBlur,
        inputReset: messageReset,
    } = useValidation(emptyCheck);

    // Closing form action
    const cancelFormHandler = () => {
        props.cancelForm();
    };

    let formIsValid = false;

    formIsValid = firstNameValid && lastNameValid && phoneNumberValid && locationValid && emailValid && messageValid;


    // Place order!!!
    const submitHandler = (event) => {
        event.preventDefault();

        if (!formIsValid) {
            firstNameBlur();
            lastNameBlur();
            phoneNumberBlur();
            locationBlur();
            emailBlur();
            messageBlur();
            return
        }
        console.log(message);
        emailjs.sendForm(
            'service_9913vkf',
            'template_s71od4y',
            orderFormRef.current,
            {publicKey: 'GlErRo0PfTnqR9nXt'}
        )
        .then((result) => {
            console.log('Email sent successfully:', result.text);
        })
        .catch((error) => {
            console.error('Email failed to send:', error);
        });
        
        firstNameReset();
        lastNameReset();
        setCountryCode('+1');
        setSelectedCountry('United States');
        phoneNumberReset();
        locationReset();
        emailReset();
        messageReset();
        props.cancelForm();
    };

    const codeObject = require('../../static/countryCodes.json');
    const countryCodes = codeObject.countries;

    const countryCodeHandler = (e) => {
        const selectedCountry = e.target.value;
        countryCodes.map((cc) => {
            if(cc.name === selectedCountry) {
                setCountryCode(cc.code);
            }
        })
    };

    const firstNameClass = !firstNameError ? classes.form_field : `${classes.form_field} ${classes.input_error}`;
    const lastNameClass = !lastNameError ? classes.form_field : `${classes.form_field} ${classes.input_error}`;
    const phoneNumberClass = !phoneNumberError ? classes.form_field : `${classes.form_field} ${classes.input_error}`;
    const locationClass = !locationError ? classes.form_field : `${classes.form_field} ${classes.input_error}`;
    const emailClass = !emailError ? classes.form_field : `${classes.form_field} ${classes.input_error}`;
    const messageClass = !messageError ? `${classes.form_field} ${classes.message_class}` : `${classes.form_field} ${classes.input_error} ${classes.message_class}`;
    
    return (
        <> 
            <div className={auxCls.dets_header}>
                <div className={auxCls.product_name}>{props.dets.name}</div>
                {props.dets.sn !== 'C08773' ? <div className={auxCls.price}>Fidelity Price: ${props.dets.sale_price}</div> : <div className={auxCls.price}>{props.dets.sale_price}</div>}
                <div className={auxCls.prompt_message}>Message us here and we will get back to you shortly!</div>
            </div>
            <form onSubmit={submitHandler} className={classes.form} ref={orderFormRef}>
                <input 
                    type="hidden"
                    name="productName"
                    value={props.dets.name}
                />
                <input 
                    type="hidden"
                    name="productSN"
                    value={props.dets.sn}
                />
                <input 
                    type="hidden"
                    name="productPrice"
                    value={props.dets.sale_price}
                />
                <input 
                    className={firstNameClass}
                    type='text'
                    name='firstName'
                    value={firstName}
                    placeholder='First Name'
                    onBlur={firstNameBlur}
                    onChange={firstNameHandler}
                />
                <div className={classes.error_message}>{firstNameError && <>Please provide your first name</>}</div>
                <input
                    className={lastNameClass}
                    type='text' 
                    name='lastName'
                    value={lastName}
                    placeholder='Last Name' 
                    onBlur={lastNameBlur}
                    onChange={lastNameHandler} 
                />
                <div className={classes.error_message}>{lastNameError && <>Please provide your last name</>}</div>
                <select className={classes.countries} onChange={countryCodeHandler}>
                    {countryCodes.map((cc) => {
                        const countryName = cc.name;
                        const option = cc.name === 'United States' ? <option value={countryName} selected>{countryName}</option> : <option value={countryName}>{countryName}</option>
                        return option;
                    })}
                </select>
                <input type='hidden' name='countryCode' value={countryCode} />
                <div className={classes.cel_section}>
                    <span className={classes.country_code}>{countryCode}</span>
                    <input 
                        className={phoneNumberClass}
                        type='tel'
                        name='phone'
                        value={phoneNumber}
                        placeholder='Phone Number' 
                        onBlur={phoneNumberBlur}
                        onChange={phoneNumberHandler} 
                    />
                </div>
                <div className={classes.error_message}>{phoneNumberError && <>Please provide a valid phone number</>}</div>
                <input 
                    className={locationClass}
                    type='text' 
                    name='dentalSchoolOffice'
                    value={location}
                    placeholder='Dental School/Office'
                    onBlur={locationBlur}
                    onChange={locationHandler} 
                />
                <div className={classes.error_message}>{locationError && <>Please provide your dental school or office</>}</div>
                <input 
                    className={emailClass}
                    type='email'
                    name='email'
                    value={email}
                    placeholder='Email'
                    onBlur={emailBlur}
                    onChange={emailHandler}
                />
                <div className={classes.error_message}>{emailError && <>Please provide a valid email address</>}</div>
                <textarea 
                    className={messageClass} 
                    name='message'
                    value={message}
                    placeholder='Leave message here'
                    onBlur={messageBlur}
                    onChange={messageHandler}
                />
                <div className={classes.error_message}>{messageError && <>Please provide a message</>}</div>
                <div className={classes.buttons}>
                    <button className={classes.order_button} type='submit'>Place Order</button>
                    <button className={classes.order_button} onClick={cancelFormHandler}>Cancel</button>
                </div>
            </form>
        </>
    )
};

export default OrderForm;