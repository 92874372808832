import classes from './LowSpeeds.module.css';
import StoreItem from './StoreItem';

const HighSpeeds = (props) => {
    console.log(props.product_data);

    return (
        <>
            <h3 className={classes.ls_title}>Pneumatic Low Speeds</h3>
            <div className={classes.low_speeds}>
                {props.lsProductData.map((item) => (
                    <StoreItem key={item.id} item={item} viewProduct={props.onAppear} />
                ))}
            </div>
        </>
    )
};

export default HighSpeeds;