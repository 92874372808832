import classes from './StoreItem.module.css';

const StoreItem = (props) => {

    const ViewProductHandler = () => {
        const product_info = [props.item.id, props.item.high_speed];
        props.viewProduct(product_info);
    };

    // let view_product = props.item.sold ? <button className={classes.view_disabled} onClick={ViewProductHandler} disabled>Product Sold</button> : <button className={classes.view_botton} onClick={ViewProductHandler}>View Product</button>
    const edge_case = 'STAR TITAN SWIVEL 20K AIR MOTORS & STAR DENTAL QUICK DISCONNECT'
    const view_product = props.item.name === edge_case ? <button className={classes.botton_edge_case} onClick={ViewProductHandler}>View Product</button> : props.item.sold ? <button className={classes.view_disabled} onClick={ViewProductHandler} disabled>Product Sold</button> : <button className={classes.view_botton} onClick={ViewProductHandler}>View Product</button>


    return (
        <div key={props.item.id} className={classes.box}>
            <div className={classes.contain}>
                <div className={classes.img_box}>
                    <img src={props.item.tn_img} alt={`${props.item.name} img`} />
                </div>
                <div className={classes.details}>
                    <div className={classes.info}>
                        { props.item.name !== edge_case ? <h5 className={classes.titles}>{props.item.name}</h5> : <h5 className={classes.edge_case_title}>{props.item.name}</h5> }
                        <p className={classes.prices}>
                            {props.item.org_price !== "SOLD" && <>$</>}{props.item.sale_price}{ props.item.org_price !== 'SOLD' && props.item.org_price !== 'none' ? <span className={classes.old_price}>${props.item.org_price}</span> : null}</p>
                    </div>
                    <div className={classes.button}>
                        {view_product}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StoreItem;