import classes from './Quotes.module.css';
import source_img1 from '../../static/Suteera_Hovijitra.jpg';
import source_img2 from '../../static/dr-eder.jpg';
import source_img3 from '../../static/dr-ullom.jpg';
import source_img4 from '../../static/dr-brennan.jpeg';
import source_img5 from '../../static/dr-martine.jpeg';
import source_img6 from '../../static/dr-wittrig.jpeg'

const Quotes = () => {
    return (
        <div className={classes.all_quotes}>
            <div className={classes.block_quote}>
                <p className={classes.quote}>
                    <b>Fidelity</b> has "provided me with very, very good service... very conscientious and reliable..."
                </p>
                <div className={classes.source}>
                    Dr. Suteera Hovijitra, DDS MSD <br />
                    (IUSD Prosthodontics Faculty) <br />
                    Practice: Indianapolis (North), Indiana
                </div>
            </div>
            <div className={classes.block_quote}>
                <p className={classes.quote}>
                    "[I] Have been using Fidelity for 7 years and am extremely pleased with the quality and efficiency of the Company.
                    I have had slow speed and high speed handpieces done - from simple repairs to complete overhauls and have never been disappointed."
                </p>
                <div className={classes.source}>
                    Dr. Todd R. Eder, DDS <br />
                    Brownsburg, Indiana
                </div>
            </div>
            <div className={classes.block_quote}>
                <p className={classes.quote}>
                    "...Efficient work at reasonable cost and has a quick turn around time... 
                    It is done locally and is better than sending it [the handpiece] out somewhere."
                </p>
                <div className={classes.source}>
                    Dr. Ben Turow, DDS<br />
                    Indianapolis (East), Indiana
                </div>
            </div>
            <div className={classes.block_quote}>
                <p className={classes.quote}>
                    "I can easily recommend Fidelity since I am personally acquainted with Rod Feeney 
                    and know he is a man of integrity and gives close attention to details."
                </p>
                <div className={classes.source}>
                    Dr. Virgil Ullom, DDS <br />
                    The Caribbean / Haiti / Missionary
                </div>
            </div>
            <div className={classes.block_quote}>
                <p className={classes.quote}>
                    "...Excellence with a smile..."
                </p>
                <div className={classes.source}>
                    Dr. Mairtin N. Brennan, DDS MSD <br />
                    Prosthodontics <br />
                    Dublin, Ireland
                </div>
            </div>
            <div className={classes.block_quote}>
                <p className={classes.quote}>
                    "Thank you for all the excellent service you give to my handpieces. I have always been impressed with the high quality and 
                    promptness of your work. You always seem to be able to do repairs that others can't or are unwilling to do. You have been 
                    more than fair..."
                </p>
                <div className={classes.source}>
                    Sincerely, <br />
                    John Rufatto, DDS <br />
                    Zionsville, Indiana
                </div>
            </div>
            <div className={classes.block_quote}>
                <p className={classes.quote}>
                    "Your service turn around time has been exceptional... No other Handpiece Service has been able to match Fidelity... We are very
                    pleased with Fidelity's knowledge of different handpieces and the ability to discuss the problems that are associated with 
                    the different handpieces."
                </p>
                <div className={classes.source}>
                    Dr. Gary R. Martine, DDS <br />
                    Indianapolis (Northwest), Indiana
                </div>
            </div>
            <div className={classes.block_quote}>
                <p className={classes.quote}>
                    "I have always been very pleased with Mr. Feeney's work. His honesty and pride in service are to be admired."
                </p>
                <div className={classes.source}>
                    Dr. Erin E. Wittrig. DDS<br />
                    Practice limited to Periodontics<br />
                    Greenwood, Indiana
                </div>
            </div>
        </div>
    );
}

export default Quotes;