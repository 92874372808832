import classes from './Warranty.module.css';

const Warranty = () => {
    return (
        <div className={classes.warranty_page}>
            <h1 className={classes.title}>Warranty</h1>
            <h3 className={classes.pledge}>Our Pledge to You: <span className={classes.emphasis}>THREE MONTH WARRANTY</span></h3>
            <p className={classes.content}>
                Fidelity warranties all new handpieces and new parts for 90 days from the date 
                of invoice. The Company pledges that it will repair or replace free and without 
                labor or return charge any defective part, if such a defect results from normal 
                use and is not the result of apparent misuse, or abuse.
            </p>
        </div>
    )
};

export default Warranty;